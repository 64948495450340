@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap');
@font-face {
  font-family: 'SpaceAndAstronomy';
  src: local('SpaceAndAstronomy'), url(../fonts/SpaceAndAstronomy.ttf) format('truetype');
}
@font-face {
  font-family: 'Faerie';
  src: local('Faerie'), url(../fonts/Faerie.ttf) format('truetype');
}
@font-face {
  font-family: 'Metamorphous';
  src: local('Metamorphous'), url(../fonts/Metamorphous.ttf) format('truetype');
}
body {
  background: #031627;
}

.background {
  height: 100vh;
  position: absolute;
  top: 0px;
  width: 100vw;
  min-height: 600px;
  min-width: 1340px;
  z-index: -1;
  position: fixed;
}
