.home h1 {
    font-family: 'Yusei Magic', sans-serif;
    background: rgb(249,165,88);
    background: radial-gradient(circle, rgba(249,165,88,1) 18%, rgba(244,188,109,1) 51%, rgba(249,165,88,1) 85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2em;
    text-align: left;
}
  
.home p {
    font-family: 'Metamorphous';
    font-size: 0.6em;
    /* width: 300px; */
    text-align: justify;
    color: #f4eece;
    line-height: 25px;
}

.home button {
    background-color: #f4eece !important;
    /* border: 1px solid #f4eece !important; */
    color: black !important;
    float: right;
  }

  @media only screen and (min-width: 1800px) {
    .home h1 {
        margin-top: 100px;
        font-size: 2.25em;
    }
    .home p { 
        font-size: 0.8em;
        line-height: 32.5px;
    }
  }