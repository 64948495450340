.generator {
    padding-top: 25px;
}
    .generator .form {
        /* background-color: rgba(26, 32, 42, 0.603); */
        /* background-color: rgba(8, 18, 34, 0.462); */
        background-color: rgba(8, 18, 34, 0.822);
        width: 100%;
        border-radius: 15px;
        height: fit-content;
        margin-left: 50px;
        /* box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        -webkit-box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75); */
        padding: 0px 25px 25px;
    }
        .generator .form h1 {
            font-size: 1.2em;
            font-family: 'Metamorphous';
            color: white;
            display: block;
            width: 100%;
        }
        .generator .form label, .generator .form textarea {
            font-family: 'Metamorphous';
            font-size: 0.8em;
        }
        .generator .form p {
            color: rgba(255, 255, 255, 0.7);
            padding: 0px;
            margin: 0px;
            font-size: 0.75em;
            font-family: 'Metamorphous';
        }
        .generator .form .submitButton {
            margin-top: 20px;
        }
    .generator .form .bookCoverSwitch {
        position: relative;
        top: 65%;
        transform: translateY(-50%);
    }
        .generator .form .bookCoverSwitch span {
            font-family: 'Metamorphous';
            font-size: 0.9em;
            color: rgba(255, 255, 255, 0.7);
        }
    .generator .form .ratioText {
        margin-bottom: 10px;
    }
    .generator .tempBook {
        text-align: center;
        border-radius: 7.5px;
        box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        -webkit-box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        width: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .generator .tempBook .bookCover{
        width: 100%;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .generator .tempBook p {
        font-size: 2em;
        /* color: #c09a4f; */
        padding: 0px;
        font-family: 'Faerie';
        position: relative;
        background: rgb(249,165,88);
        background: radial-gradient(circle, rgba(249,165,88,1) 18%, rgba(244,188,109,1) 51%, rgba(249,165,88,1) 85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        top: 25%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 72.5%;
        z-index: 1;
    }
    .generator .tempBook .pageLoadingBar {
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
    }
    .generator .tempBook .pageLoadingBar p {
        font-size: 1em;
        color: white;
        padding: 0px;
        font-family: 'Metamorphous';
    }




    .generator .bookCover {
        /* background-image: url(../assets/book2:3.png); */
        /* background-repeat: round; */
        /* This is for the loading bar */
        text-align: center;
        border-radius: 7.5px;
        box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        -webkit-box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.75);
        position: relative;
    }
        .generator .bookCover .defaultCover{
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        .generator .bookCover .genCover{
            position: absolute;
            width: 95%;
            height: 97%;
            border-radius: 7.5px;
            /* transform: translateY(-50%); */
            top: 50%;
            left: 50%;
            transform:translate(-50%,-50%);
        }
        .generator .bookCover p {
            font-size: 2em;
            /* color: #c09a4f; */
            padding: 0px;
            font-family: 'Faerie';
            position: relative;
            background: rgb(249,165,88);
            background: radial-gradient(circle, rgba(249,165,88,1) 18%, rgba(244,188,109,1) 51%, rgba(249,165,88,1) 85%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            top: 20%;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 72.5%;
            z-index: 1;
        }
        .generator .bookCover .pageLoadingBar {
            position: relative;
            display: none;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            width: 70%;
        }
        .generator .bookCover .pageLoadingBar p {
            font-size: 1em;
            color: white;
            padding: 0px;
            font-family: 'Metamorphous';
        }
    .generator .leftPage p, .generator .rightPage p {
        font-family: 'Metamorphous';
        padding: 10% 4% 0% 16%;
        font-size: 1.05em;
        line-height: 1.5em;
        white-space: pre-line;
    } 
    .generator .leftPage {
        background-image: url(../assets/leftPage.png);
        background-repeat: round;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .generator .threebytwo {
        background-image: url(../assets/page3by2.jpg);
        background-repeat: round;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .generator .rightPage {
        background-image: url(../assets/rightPage.png);
        background-repeat: round;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .generator .rightPage .twobythree {
        max-width: 80%;
        max-height: 77.5%;
        top: 10%;
        left: 45%;
        transform: translateX(-50%);
        aspect-ratio: 2/3;
        position: relative;
        box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.55);
    }
        .generator .rightPage .twobythree img{ aspect-ratio: 2/3; }
    .generator .rightPage .threebytwo {
        box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.55);
        max-width: 80%;
        max-height: 80%;
        top: 12.5%;
        left: 42.5%;
        transform: translateX(-50%);
        aspect-ratio: 3/2;
        position: relative;
    }
        .generator .rightPage .threebytwo img { aspect-ratio: 3/2; }
    .generator .rightPage .twobytwo {
        box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.55);
        max-width:  77.5%;
        max-height: 77.5%;

        top: 10%;
        left: 45%;
        transform: translateX(-50%);
        aspect-ratio: 1/1;
        position: relative;
    }
        .generator .rightPage .twobytwo img { aspect-ratio: 1/1; }
    .generator .rightPage img{
        position: absolute;
        width: 100%;
        height: 100%;
        /* width: 80%;
        top: 10%;
        left: 4%;
        max-height: 77.5%;
        position: absolute;
        /* margin: 15% 0px 0px 4%; */
        /* box-shadow: 1px 2px 39px -12px rgba(0,0,0,0.55); */ 
    }


@media only screen and (min-width: 1800px) {
    .generator .leftPage p, .generator .rightPage p {
        font-size: 1.5em;
        line-height: 2em;
    }
}