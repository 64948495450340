.header {
    width: 100vw;
    height: 100px;
  }
      .header a {
          line-height: 100px;
          font-size: 1.1em;
          font-family: 'Faerie';
          position: inherit;
          display: inline-block;
          width: auto;
          text-decoration: none;
      }
      .header a:hover {
          cursor: pointer;
          transform: scale(1.2);
      }
      .header .leftElements a{
          margin: 0px 0px 0px 50px;
          color: #f4eece;
      }
      .header .rightElements a{
          margin: 0px 50px 0px 0px;
          color:#000000;
          font-size: 1.2em;
      }
      .header img {
          width: 40px;
          margin-left: 35px;
          vertical-align: middle;
      }
      .header .rightElements img {
          margin: 0px 35px 0px 0px;
      }